<template>
    <div class="home">
        <div class = "user">
            <div @click="beginEditName" v-if="!iseditname">Hi，{{nickname}}</div>
            <div v-if="iseditname">Hi，<input ref="nameInput" class = "editnameinput"  v-model="nickname" v-on:blur="changeName"  @keydown.enter="handleNameEnter"></div>
        </div>
        <img src="../assets/homeimg.png" />
        <div class="tips">
            <div class="search">
            <div>输入<span>群名或聊天码</span>进入聊天</div>
                <img src="../assets/hometips-search.png" />
            </div>
            <div class="share">
                <div><span>分享</span>给朋友，<span>无需注册</span>随时进群</div>
                <img src="../assets/hometips-share.png" />
            </div>
        </div>
        <div class="bottom-bar">
            <p> 广州此友网络科技有限公司 · Copyright @2024 在此 All Rights Reserved · <a href="https://beian.miit.gov.cn/">粤ICP备2024263943号-2</a></p>
        </div>
    </div>
</template>

<script>

import {postChangeName} from "../func/requestHandler" 
export default{
    props: {
        name: String
    },
    data(){
        return {
            nickname:"登录中...",
            iseditname:false,
            oldname: ""
        }
    },
    watch: {
        name(val){
            this.nickname = val;
        }
    },
    methods:{
        beginEditName(){
            this.iseditname = true
            this.$nextTick(()=>{
                this.$refs.nameInput.focus();
            })
            this.oldname = this.nickname
        },
        async changeName(){
            //如果输入为空，不做任何处理，恢复成旧名字
            if(this.nickname == ""){
                this.nickname = this.oldname
                this.iseditname = false
                return 
            }
            this.iseditname = false
            var result = await postChangeName(this.nickname)
            if(result.succeed){
                //如果更新成功，记录新值
                this.oldname = this.nickname
                console.log("更新昵称成功")
            }else{
                this.nickname = this.oldname
                this.$message.error(result.message);
            }

        },
        handleNameEnter(e){
            // 在回车事件中，手动触发input的blur事件，防止enter和blur触发两次
            e.target.blur();
        }
    },
    created(){
        this.nickname = this.name;
    }
    
}
</script>

<style lang="scss" scoped>
.home{
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #FAFAFA;
    overflow: hidden;
    user-select: none; /* 禁用文字选择 */

    & > img{
        width: 321px;
        height: 321px;
        position: absolute;
        top: 45%;
        //top: calc(25% - 80px);
        left: 50%;
        transform: translateX(-50%) translateY(-70%);
        //transform: translateX(-50%);
    }
}
.user{
    font-size: 16px;
    color: rgb(204, 204, 204); 
    position: absolute;
    top: 20px;
    left: 20px;
}
.editnameinput{
    color: #666666;
    font-size: 16px;
    background-color: rgba(255, 255, 255, 0.98);
    border: 0;
    outline: none;
}
.tips{
    width: 100%;
    position: absolute;
    top: calc(50% + 161px);
    display: flex;
    justify-content: space-around;
    text-align: center;
    flex-wrap: wrap;
    color: #666666;
    font-size: 18px;

    .search, .share{
        padding: 0 15px;
    }

    span {
        color: #F8DB9c;
        font-weight: bold;
    }

    .search img{
        width: 204px;
        // height: 63px;
    }
    .share img{
        width: 204px;
        // height: 48px;
    }
}
.bottom-bar {
    width: 100%;
    text-align: center; /* 文本居中 */
    padding: 6px 0; /* 顶部和底部内边距 */
    color: #aaa; /* 文字颜色，通常为深灰色或黑色 */
    font-size: 12px; /* 字体大小，根据需要调整 */
    line-height: 1.5; /* 行高，确保文本紧凑 */
    position: absolute; /* 绝对定位 */
    bottom: 0; /* 底部对齐 */
    left: 0;
    right: 0;
    background-color: transparent; /* 无背景色 */
    border: none; /* 无边框 */
    
}

/* 如果有链接，可以添加以下样式 */
.bottom-bar a {
    color: #aaa; /* 链接颜色 */
    text-decoration: none; /* 无下划线 */
    font-size: inherit; /* 链接字体大小继承自 .bottom */
    cursor: pointer;
}
</style>