<template>
    <div class="download-page">
      <div class="download-tips">
        <div>
          <p class="download-progress">app下载中...</p>
          <p>如无法下载，请复制以下链接并在手机系统浏览器中打开:</p>
          <p>https://zaici.cn/download</p>
          <button class="download-close" >关闭页面</button>
        </div>
      </div>
      <div>
        
      </div>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        isAndroid: false,
        isIOS: false,
        isSupportedBrowser: false
      };
    },
    mounted() {
      // 判断设备类型
      this.isAndroid = /android/i.test(navigator.userAgent);
      this.isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  
      // 判断是否支持跳转
      this.isSupportedBrowser = /(chrome|firefox|safari)/i.test(navigator.userAgent);

      if (this.isAndroid) {
        // 安卓设备，直接下载安卓APP
        window.location.href = 'https://zaicires.oss-cn-hangzhou.aliyuncs.com/pkg/zaici.apk';
      } else if (this.isIOS && this.isSupportedBrowser) {
        // iOS设备，支持跳转，直接跳转到App Store
        window.location.href = 'https://itunes.apple.com/cn/app/id1599916138?mt=8';
      }
    }
  };
  </script>
<style>
.download-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.download-tips {
  text-align: center;
  font-size: 15px;
  color: #333;
  margin-bottom: 20%;
}
.download-progress {
  font-size: 24px;
  padding-bottom: 10px;
}

.download-tips p {
  margin: 10px 0;
}

.download-close{
  /*暂时不显示这个 */
  display: none;
  height: 36px;
  min-width: 100px;
  border: solid 1px #333;
  color: #333;
  background-color: #Fff;
  font-size: 15px;
  border-radius: 100px;
  margin-top: 100px;
  
}

</style>
  